.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // width: 100%;
  // gap: 40px;
  // margin-top: 2rem;

  @media screen and (max-width: 900px) {
    // grid-template-columns: 1fr;
  }
}

.app__profile-item {
  width: 290px;
  // width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 1rem;

  img {
    width: 100%;
    height: 300px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
      // width: 190px;
    }
  }
}
