.coverletter{
    padding: 5rem 2rem;
    position: relative;
    // margin-top: 3rem;
    ul{
        margin: 1rem 0;
        li{
            list-style: decimal inside none;
            margin-bottom: 1rem;
        }
    }
}