.certification {
  position: relative;
  background: url("../../assets/bgIMG.png");
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  height: 100%;
  padding: 5rem 1rem 0 1rem;
}

// .app__about {
//     flex: 1;
//     width: 100%;
//     flex-direction: column;
//   }

.certification__profiles {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
  }
}

.certification__profile-item {
  // width: 190px;
  //   width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  //   margin-left: 0.5rem;

  img {
    width: 100%;
    height: 350px;
    border-radius: 15px;
    object-fit: cover;
    object-position: top left;
  }

  h2 {
    text-transform: capitalize;
  }

  @media screen and (min-width: 2000px) {
    // width: 370px;
    // margin: 2rem 4rem;

    // img {
    //   height: 320px;
    //   // width: 190px;
    // }
  }
}
